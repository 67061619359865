import React, { useEffect } from 'react'; 
import { Link } from 'react-router-dom' 
import Header from '../Component/Header';
import Footer from '../Component/Footer';
import LazyLoad from 'react-lazyload';
const FeeStructure = () => {
    useEffect(() => {
        document.title = 'FeeStructure - St Joseph Convent School, Phagwara';  
    }, []);  
  return (
     <>
     <Header></Header>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                 
                 <li>Fee Structure</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Fee Structure</h1>
                    </div>
                </div>
                <div className="row"> 
                    <div className='col-xl-3'>
                        <Link to="https://webapi.entab.info/api/image/SJSP/public/pdf/nur-ukg-fee-structure.pdf" target='_blank'>
                        <div className='feeStructure'>
                            <img src="https://webapi.entab.info/api/image/SJSP/public/Images/pdf-icon.png" className='img-fluid' alt="Fee Structure-SJSP" />
                            Class - Nur to UKG
                        </div>
                        </Link>
                    </div> 
                    <div className='col-xl-3'>
                        <Link to="https://webapi.entab.info/api/image/SJSP/public/pdf/I-fee-structure.pdf" target='_blank'>
                        <div className='feeStructure'>
                            <img src="https://webapi.entab.info/api/image/SJSP/public/Images/pdf-icon.png" className='img-fluid' alt="Fee Structure-SJSP" />
                            Class - I
                        </div>
                        </Link>
                    </div> 
                    <div className='col-xl-3'>
                        <Link to="https://webapi.entab.info/api/image/SJSP/public/pdf/II-fee-structure.pdf" target='_blank'>
                        <div className='feeStructure'>
                            <img src="https://webapi.entab.info/api/image/SJSP/public/Images/pdf-icon.png" className='img-fluid' alt="Fee Structure-SJSP" />
                            Class - II
                        </div>
                        </Link>
                    </div> 
                    <div className='col-xl-3'>
                        <Link to="https://webapi.entab.info/api/image/SJSP/public/pdf/III-VII-fee-structure.pdf" target='_blank'>
                        <div className='feeStructure'>
                            <img src="https://webapi.entab.info/api/image/SJSP/public/Images/pdf-icon.png" className='img-fluid' alt="Fee Structure-SJSP" />
                            Class - III to VII
                        </div>
                        </Link>
                    </div> 
                    <div className='col-xl-3'>
                        <Link to="https://webapi.entab.info/api/image/SJSP/public/pdf/VIII-fee-structure.pdf" target='_blank'>
                        <div className='feeStructure'>
                            <img src="https://webapi.entab.info/api/image/SJSP/public/Images/pdf-icon.png" className='img-fluid' alt="Fee Structure-SJSP" />
                            Class - VIII
                        </div>
                        </Link>
                    </div> 
                    <div className='col-xl-3'>
                        <Link to="https://webapi.entab.info/api/image/SJSP/public/pdf/IX-X-fee-structure.pdf" target='_blank'>
                        <div className='feeStructure'>
                            <img src="https://webapi.entab.info/api/image/SJSP/public/Images/pdf-icon.png" className='img-fluid' alt="Fee Structure-SJSP" />
                            Class - IX to X
                        </div>
                        </Link>
                    </div> 
                    <div className='col-xl-3'>
                        <Link to="https://webapi.entab.info/api/image/SJSP/public/pdf/XI-XII-comm-fee-structure.pdf" target='_blank'>
                        <div className='feeStructure'>
                            <img src="https://webapi.entab.info/api/image/SJSP/public/Images/pdf-icon.png" className='img-fluid' alt="Fee Structure-SJSP" />
                            Class - XI - XII (Commerce )
                        </div>
                        </Link>
                    </div> 
                    <div className='col-xl-3'>
                        <Link to="https://webapi.entab.info/api/image/SJSP/public/pdf/XI-XII-med-nonmed-structure.pdf" target='_blank'>
                        <div className='feeStructure'>
                            <img src="https://webapi.entab.info/api/image/SJSP/public/Images/pdf-icon.png" className='img-fluid' alt="Fee Structure-SJSP" />
                            Class - XI - XII (Med, Non.Med.) 
                        </div>
                        </Link>
                    </div> 
                 </div>
            </div>
        </div>
        <Footer />
     </>
  )
}

export default FeeStructure

