
import { useEffect } from 'react';
import { useLocation, NavLink, Link } from 'react-router-dom';
import OnlineRegistration from '../Component/OnlineRegistration'
import Highlights from '../Component/Highlights'
import LazyLoad from 'react-lazyload';
const Header = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <div className="header">
        <div className="logo">
       <img src="https://webapi.entab.info/api/image/SJSP/public/Images/jesus1.png" alt="St Joseph School" />
          <NavLink to="/"><LazyLoad><img src="https://webapi.entab.info/api/image/SJSP/public/Images/logo.png" alt="Gandhi High School, Sidhi - Madhya Pradesh" className="img-fluid" /></LazyLoad></NavLink>
        </div>
        <div className="header-nav">
          <Highlights />
          <div className='right-align'>
            <OnlineRegistration />
            <Link to="/ErpSoftware">School ERP Software</Link> |
            <Link to="/Pphelp">Parent Portal(Help?)</Link>
          </div>
          <nav className="navbar navbar-expand-lg">
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="main_nav">
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <div className="nav-link" data-bs-toggle="dropdown">  About Us</div>
                 <ul className="dropdown-menu fade-up"> 
                                <li><NavLink  className="dropdown-item" to="/Motto">Motto</NavLink></li>
                                <li><NavLink  className="dropdown-item" to="/Prayer">Prayer</NavLink></li> 
                                <li><NavLink  className="dropdown-item" to="/GeneralInformation">General Information</NavLink></li> 
                                <li><NavLink  className="dropdown-item" to="/HeavenlyPatrons">Our Heavenly Patron</NavLink></li>
                                <li><NavLink  className="dropdown-item" to="/VisionAim">Vision, Aim and Objectives</NavLink></li> 
                                <li><NavLink  className="dropdown-item" to="/AffiliationCourse">Affiliation and Course of Study</NavLink></li> 
                                <li><NavLink  className="dropdown-item" to="/Faculty">Faculty</NavLink></li> 
                                <li><NavLink  className="dropdown-item" to="/Safety">Safety</NavLink></li> 
                           </ul>   
                </li>
                <li className="nav-item dropdown">
                  <div className="nav-link" data-bs-toggle="dropdown"><NavLink className="dropdown-item" to="/">Admission </NavLink></div>
                  <ul className="dropdown-menu fade-up">
                    <li><NavLink className="dropdown-item" to="/AdmissionRequirement">Admission Requirements </NavLink></li>
                    <li><NavLink className="dropdown-item" to="/Registration">Registration</NavLink></li>
                 
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <div className="nav-link" data-bs-toggle="dropdown"><NavLink className="dropdown-item" to="/"> Academics</NavLink> </div>
                   <ul className="dropdown-menu fade-up">
                            <li> <NavLink className="dropdown-item" to="/Curricular">Co-curricular Activities</NavLink></li>
                            <li> <NavLink className="dropdown-item" to="/SportsAndGames">Sports and Games</NavLink></li>  
                            <li> <NavLink className="dropdown-item" to="/Uniform">School Uniform</NavLink></li>  
                            <li> <NavLink className="dropdown-item" to="/SchoolHours">School Hours</NavLink></li>  
                            <li> <NavLink className="dropdown-item" to="/RulesAndRegulations">Rules and Regulations</NavLink></li>  
                            <li> <NavLink className="dropdown-item" to="/ExaminatioPromotion">Examinations &amp; Promotions</NavLink></li>  
                            <li> <NavLink className="dropdown-item" to="/Leadership">Discipline &amp; Leadership</NavLink></li>  
                            <li> <NavLink className="dropdown-item" to="/FeeStructure">Fee Structure</NavLink></li>  
                            <li> <NavLink className="dropdown-item" to="https://webapi.entab.info/api/image/SJSP/public/pdf/Booklist.pdf" target='_blank'>Booklist</NavLink></li>  
                        </ul>  
                </li>
                <li className="nav-item dropdown">
                  <div className="nav-link" data-bs-toggle="dropdown"><NavLink className="dropdown-item" to="/"> Infrastructure</NavLink></div>
                   <ul className="dropdown-menu fade-up">
                            <li> <NavLink className="dropdown-item" to="/ChemistryLab">Chemistry Lab </NavLink></li>
                            <li> <NavLink className="dropdown-item" to="/PhysicsLab">Physics Lab</NavLink></li> 
                            <li> <NavLink className="dropdown-item" to="/BioLab">Biology Lab</NavLink></li> 
                            <li> <NavLink className="dropdown-item" to="/Library">Library</NavLink></li> 
                            <li> <NavLink className="dropdown-item" to="/ComputerLab">Computer Lab</NavLink></li>  
                            <li> <NavLink className="dropdown-item" to="/LanguageLab">Language Lab</NavLink></li>  
                            <li> <NavLink className="dropdown-item" to="/ClassRoom">Class Room</NavLink></li>  
                            <li> <NavLink className="dropdown-item" to="/ArtCraft">Art &amp; Craft Room</NavLink></li>  
                            <li> <NavLink className="dropdown-item" to="/KarateYoga">Karate &amp; Yoga</NavLink></li>  
                            <li> <NavLink className="dropdown-item" to="/MusicDance">Music &amp; Dance</NavLink></li>  
                        </ul>   
                </li> 
                <li className="nav-item"> <div className="nav-link" ><NavLink  to="/ContactUs"> Contact US </NavLink></div></li>
               
              </ul>
            </div>
          </nav>
        </div>
        <div className='right-header'>
          <LazyLoad><img src="https://webapi.entab.info/api/image/SJSP/public/Images/jesus2.png" alt="St Joseph School" /></LazyLoad>
          <div className='social-media'>
            <Link to="https://www.facebook.com/StJosephsConventSchoolPhagwara/?hc_ref=ARRht-8mezJ2j4fBnJ0oS0q7rPfsEGmNvY4O1uR2Sm6AZr6oXJUn0Q13IQcttG9G8wM&fref=nf" target='_blank'><i class="bi bi-facebook"></i></Link>
            <Link to="https://www.youtube.com/embed/pMf79DuDWBg?rel=0" target="_blank"><i class="bi bi-youtube"></i></Link>
            <Link to="/"><i class="bi bi-instagram"></i></Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
